<template>
    <div class="sublist-wrapper">
        <component :is="tableBodySettings.component" v-bind="tableBodySettings.props" @end="handleDragEnd">
            <tr v-for="(subitem, i) in list" :key="subitem[searchFilterIndex].toString() + i" class="sublist-wrapper__row neo-table__body__row tw-w-full tw-flex hover:tw-bg-blue-50">
                <td v-if="toggleSubHeader" class="tw-ml-16 cell--toggle">
                    <div>
                        <neo-toggle-switch :status="subitem[toggleSubHeader.value]" :disabled="isLoading" class="tw-cursor-pointer" :key="subitem[toggleSubHeader.value].toString() + i" @click="check(subitem, !subitem[toggleSubHeader.value])" />
                    </div>
                </td>
                <td v-else class="cell--dropdown"></td>
                <td
                    v-for="(itemProp, index) in filteredSublistHeaders"
                    class="tw-m-0 tw-px-8 tw-py-4 tw-text-left tw-self-stretch tw-block tw-break-word"
                    :class="[
                        headers[index].class,
                        itemProp.class,
                        itemProp.type ? `cell--${itemProp.type}` : '',
                        {
                            'pl-0': index == underlineFromHeader,
                        },
                    ]"
                    :key="itemProp.value.toString() + index"
                >
                    <div class="tw-flex tw-items-center">
                        <span> {{ subitem[itemProp.value] }}</span>
                    </div>
                </td>
                <td v-if="editable" class="cell--controls tw-w-1/6 tw-m-0 tw-px-3 tw-py-4 tw-text-left tw-self-stretch tw-block tw-break-word">
                    <div class="wrapper tw-flex tw-items-center tw-flex-grow tw-justify-around">
                        <font-awesome-icon title="Edit" class="tw-text-brand tw-cursor-pointer" icon="edit" style="width: 16px; height: 16px" @click="$emit('update:row', {item: subitem})" />
                        <font-awesome-icon
                            title="Delete"
                            class="tw-text-brand tw-cursor-pointer"
                            icon="trash"
                            style="width: 16px; height: 16px"
                            @click="
                                $emit('remove:row', {
                                    item: subitem,
                                    type: 'sub',
                                })
                            "
                        />
                    </div>
                </td>
            </tr>
        </component>
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import NeoToggleSwitch from "@/components/toggle-switch";

const Draggable = () => import("vuedraggable");

export default {
    name: "neo-table-expandable-sublist",
    components: {
        "font-awesome-icon": FontAwesomeIcon,
        "neo-toggle-switch": NeoToggleSwitch,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: () => false,
        },
        underlineFromHeader: {
            type: Number,
            default: () => 0,
        },
        editable: {
            type: Boolean,
            default: () => false,
        },
        draggable: {
            type: String,
            default: () => "",
        },
        list: {
            type: Array,
            default: () => [],
        },
        headers: {
            type: Array,
            default: () => [],
        },
        sublistHeaders: {
            type: Array,
            default: () => [],
        },
        searchFilterIndex: {
            type: String,
            default: () => "value",
        },
    },
    computed: {
        toggleHeader() {
            return this.headers.find((h) => h.type === "toggle");
        },
        // * If there is toggle cell only for sublist, show it under parent dropdown
        toggleSubHeader() {
            return this.sublistHeaders.find((h) => h.type === "toggle" && !this.toggleHeader);
        },
        filteredSublistHeaders() {
            return this.sublistHeaders.filter((h) => h.type !== "toggle" && !this.toggleHeader);
        },
        tableBodySettings() {
            return {
                component: this.draggable ? Draggable : "div",
                props: this.draggable
                    ? {
                          tag: "div",
                          list: this.list,
                      }
                    : {},
            };
        },
    },
    methods: {
        check(item, value) {
            this.$emit("checked", {item, value});
        },
        handleDragEnd(e) {
            // Without pagination and filtering it is much simplier than at general table and we can just use output list
            this.$emit("update:list", {
                list: this.list.map((el, index) => ({...el, order: index})),
            });
        },
    },
};
</script>

<style src="../styles.scss" scoped lang="scss"></style>
